import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["message"]
  static values = { intervalTime: Number }

  connect() {
    this.messages = ["Analyse des résultats de recherche 1/4",
    "Rechercher des idées de requêtes 2/4",
    "Création des idées de titres 3/4",
    "Nettoyage et vérification 4/4"]
    this.index = 0

    this.displayMessage()

    this.interval = setInterval(() => {
      this.displayMessage()
    }, this.intervalTimeValue)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  displayMessage() {
    const message = this.messages[this.index]
    this.messageTarget.innerHTML = `<p>${message}</p>`
    this.index = (this.index + 1) % this.messages.length
  }
}
