import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['countdown'];

  connect() {
    this.countdownTarget.textContent = 130;
    let seconds = parseInt(this.countdownTarget.textContent);
    let countdown = setInterval(() => {
      seconds--;
      this.countdownTarget.textContent = seconds;
      if (seconds <= 0) {
        clearInterval(countdown);
        location.reload();
      }
    }, 1000);
  }
}
