import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["siteName", "displayBox","results" ,"siteSelect", "siteCategorySelect", "siteAuthorSelect"]


  async fetchSiteCategories(event) {
    const siteId = this.siteSelectTarget.value;

    // Chercher les auteurs
    // Faire une requête AJAX pour récupérer les catégories de site associées au site sélectionné
    const response_authors = await fetch(`/sites/${siteId}/display_site_authors`, {
      headers: {
        "Content-Type": "application/json"
      }
    });

    // Vérifier si la réponse est un succès
    if (response_authors.ok) {
      // Récupérer les données JSON de la réponse
      const data = await response_authors.json();
      console.log(data);

      // Mettre à jour la liste déroulante de catégories avec les données reçues
      this.siteAuthorSelectTarget.innerHTML = "";
      data.forEach(author => {
        const option = document.createElement("option");
        option.value = author.id;
        option.text = author.name;
        this.siteAuthorSelectTarget.add(option);
      });

      // Afficher la liste déroulante de catégories
      this.displayBoxTarget.classList.remove("hidden");
    } else {
      // Gérer l'erreur si la réponse n'est pas un succès
      console.error(response.statusText);
    }

    // Chercher les categories
    // Faire une requête AJAX pour récupérer les catégories de site associées au site sélectionné
    const response = await fetch(`/sites/${siteId}/display_site_categories`, {
      headers: {
        "Content-Type": "application/json"
      }
    });

    // Vérifier si la réponse est un succès
    if (response.ok) {
      // Récupérer les données JSON de la réponse
      const data = await response.json();
      console.log(data);

      // Mettre à jour la liste déroulante de catégories avec les données reçues
      this.siteCategorySelectTarget.innerHTML = "";
      data.forEach(category => {
        const option = document.createElement("option");
        option.value = category.id;
        option.text = category.name;
        this.siteCategorySelectTarget.add(option);
      });

      // Afficher la liste déroulante de catégories
      this.displayBoxTarget.classList.remove("hidden");
    } else {
      // Gérer l'erreur si la réponse n'est pas un succès
      console.error(response.statusText);
    }
  }

  connect() {
    this.siteSelectTarget.addEventListener('change', () => {
      this.displayBoxTarget.classList.remove('hidden');
    });
  };

  connect() {
      this.siteAuthorSelectTarget.addEventListener('change', () => {
        this.displayBoxTarget.classList.remove('hidden');
    });
  };
}
