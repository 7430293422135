// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'controllers'
import "bootstrap"
import "packs/trix_config"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("packs/select2setup")
import "select2-bootstrap-theme/dist/select2-bootstrap.min.css";
import "trix"
import "@rails/actiontext"

