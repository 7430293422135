import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['bar'];
  static values = { repeats: Number };

  connect() {
    this.repeatsValue = 4;
    this.startProgress();
  }

  startProgress() {
    let width = 0;
    let intervalId = setInterval(() => {
      if (width >= 100) {
        clearInterval(intervalId);
        this.repeatsValue--;
        if (this.repeatsValue > 0) {
          setTimeout(() => {
            this.startProgress();
          }, 0); // Attends 1 seconde avant de redémarrer la progress
        }
      } else {
        width++;
        this.barTarget.style.width = `${width}%`;
      }
    }, 323);
  }
}
